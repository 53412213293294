<template>
  <b-modal
    id="edit-password-model"
    :title="$t('g.editPassword')"
    centered
    hide-footer
  >
    <validation-observer ref="changePasswordForm">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required"
                name="current password"
                #default="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    v-model="passwordData.current"
                    :placeholder="$t('g.currentPassword')"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>

                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required"
                name="New password"
                #default="{ errors }"
                vid="pass"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    :type="newPasswordFieldType"
                    v-model="passwordData.new_password"
                    :placeholder="$t('g.newPassword')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="newPasswordToggleIcon"
                      @click="toggleNewPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group>
              <validation-provider
                rules="required|confirmed:pass"
                name="confirm password"
                #default="{ errors }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    :type="confirmPasswordFieldType"
                    v-model="passwordData.confirem_password"
                    :placeholder="$t('g.newPasswordAgian')"
                    :state="errors.length > 0 ? false : null"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="confirmPasswordToggleIcon"
                      @click="toggleConfirmPasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">
                  {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right mb-1">
          <b-button
            variant="outline-transparent"
            class="mr-1"
            @click="clearForm()"
            >{{ $t("g.cancel") }}</b-button
          >
          <b-button type="submit" variant="primary">{{
            $t("g.save")
          }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { required, password, confirmed } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BCard,
  BRow,
  BButton,
  BOverlay,
  BCol,
  BForm,
  BProgress,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormFile,
  BCardText,
  BModal,
  BInputGroupAppend,
} from "bootstrap-vue";
export default {
  name: "overlayForm",
  props: ["overlay"],
  components: {
    BButton,
    BRow,
    BCol,
    BOverlay,
    BForm,
    BProgress,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormFile,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BModal,
    ToastificationContent,
    BInputGroupAppend,
  },
  beforeMount() {
    this.show = this.$props.overlay;
  },

  data() {
    return {
      show: null,
      processing: false,
      interval: null,
      passwordData: {
        current: null,
        new_password: null,
        confirem_password: null,
      },
      passwordFieldType: "password",
      newPasswordFieldType: "password",
      confirmPasswordFieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    newPasswordToggleIcon() {
      return this.newPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
    confirmPasswordToggleIcon() {
      return this.confirmPasswordFieldType === "password"
        ? "EyeIcon"
        : "EyeOffIcon";
    },
  },
  methods: {
    togglePasswordVisibility() {
      if (this.passwordFieldType === "password") {
        this.passwordFieldType = "text";
      } else {
        this.passwordFieldType = "password";
      }
    },
    toggleNewPasswordVisibility() {
      if (this.newPasswordFieldType === "password") {
        this.newPasswordFieldType = "text";
      } else {
        this.newPasswordFieldType = "password";
      }
    },
    toggleConfirmPasswordVisibility() {
      if (this.confirmPasswordFieldType === "password") {
        this.confirmPasswordFieldType = "text";
      } else {
        this.confirmPasswordFieldType = "password";
      }
    },
    onSubmit() {
      this.$refs.changePasswordForm.validate().then((sucess) => {
        if (sucess) {
          let formData = new FormData();
          formData.append("old_password", this.passwordData.current);
          formData.append("new_password", this.passwordData.new_password);
          formData.append(
            "new_password_confirmation",
            this.passwordData.confirem_password
          );

          this.$http
            .post("auth/teacher/edit-profile?_method=put", formData)
            .then((res) => {
              this.overlay_loading = false;
              this.$helpers.makeToast(
                "success",
                this.$t("success"),
                this.$t("pass_changed_successfully")
              );
            })
            .catch((err) => {
              this.overlay_loading = false;
              this.$helpers.handleError(err);
            });
        }
      });
    },
    clearForm() {
      this.passwordData.current = null;
      this.passwordData.new_password = null;
      this.passwordData.confirem_password = null;
      this.show = false;
    },
  },
};
</script>
