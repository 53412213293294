<template>
  <div class="edit-profile">
    <loading class="loading-component" v-if="loading" />
    <b-row v-else>
      <div class="container-sm p-2">
        <b-row>
          <b-col cols="12" class="image">
            <div class="cover-img">
              <img
                :src="coverPath ? coverPath : profileCoverPlaceholder"
                alt="cover image"
              />
              <b-form-file
                ref="coverFileInput"
                @change="coverImageHandler"
                accept="image/*"
                :hidden="true"
                plain
              />
              <div
                @change="coverImageHandler"
                class="add-cover-btn-div"
                @click="$refs.coverFileInput.$el.click()"
              ></div>
            </div>
            <div class="profile-img">
              <b-avatar
                v-if="form_data.media"
                :src="path != null ? path : profileImgaePlaceholder"
                :alt="form_data.first_name"
                size="120"
              />
              <b-form-file
                ref="fileInput"
                @change="profileImageHandler"
                accept="image/*"
                :hidden="true"
                plain
              />
              <div
                @change="profileImageHandler"
                class="add-img-btn-div d-inline-block"
                @click="$refs.fileInput.$el.click()"
              ></div>
            </div>
          </b-col>
        </b-row>
        <b-row align-h="center" class="form-section">
          <validation-observer ref="form">
            <b-form>
              <!-- smallInputs -->
              <b-row align-v="start">
                <b-col md="4" class="p-0">
                  <b-form-group
                    :label="$t('g.firstName')"
                    label-for="First Name"
                    class="sm-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="First Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form_data.first_name"
                        id="First-name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        :placeholder="$t('g.firstName')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" class="p-0">
                  <b-form-group
                    :label="$t('g.middelName')"
                    label-for="Middle Name"
                    class="sm-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Middle Name"
                    >
                      <b-form-input
                        v-model="form_data.middle_name"
                        id="Middle-name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        class=""
                        :placeholder="$t('g.middelName')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="4" class="p-0">
                  <b-form-group
                    :label="$t('g.lastName')"
                    label-for="Last Name"
                    class="sm-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Last Name"
                      rules="required"
                    >
                      <b-form-input
                        v-model="form_data.last_name"
                        id="Last-name"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        :placeholder="$t('g.lastName')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <!-- **smallInputs -->
              <!-- largeInputs -->
              <b-row>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.email')"
                    label-for="email"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <b-form-input
                        v-model="form_data.email"
                        :state="errors.length > 0 ? false : null"
                        id="email"
                        type="email"
                        :placeholder="$t('g.email')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.password')"
                    label-for="password"
                    class="lg-input-style"
                  >
                    <validation-provider #default="{ errors }" name="password">
                      <b-form-input
                        v-model="password"
                        id="password"
                        :state="errors.length > 0 ? false : null"
                        type="password"
                        :placeholder="$t('g.password')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                    <span class="Edit-password-Btn" @click="handleOpenModel">
                      {{ $t("g.edit") }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.Country')"
                    label-for="Country"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Country"
                      rules="required"
                    >
                      <b-form-select
                        v-model="form_data.country_id"
                        id="Country"
                        :options="countries_options"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        :placeholder="$t('g.Country')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.phone')"
                    label-for="phone"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="phone"
                      rules="integer|min:5"
                    >
                      <b-form-input
                        v-model="form_data.phone"
                        id="phone"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        :placeholder="$t('g.phone')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.education_level')"
                    label-for="Education level"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Education level"
                      rules="required"
                    >
                      <b-form-select
                        v-model="form_data.edu_level_id"
                        id="Education level"
                        :options="levels_options"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                        :placeholder="$t('g.education_level')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="p-0">
                  <b-form-group
                    :label="$t('g.experince_year')"
                    label-for="experince year"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="experince year"
                      rules="integer|required"
                    >
                      <b-form-input
                        v-model="form_data.experience"
                        id="Experince Year"
                        :state="errors.length > 0 ? false : null"
                        type="number"
                        :placeholder="$t('g.experince_year')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="p-0">
                  <b-form-group
                    :label="$t('g.birthDate')"
                    label-for="Birth-date"
                    class="lg-input-style"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Birth date"
                    >
                      <b-form-input
                        id="Birth-date"
                        v-model="form_data.birthdate"
                        :state="errors.length > 0 ? false : null"
                        type="date"
                        :placeholder="$t('birthDate')"
                      />
                      <small class="text-danger">
                        {{ errors[0] && $t(`g.alert.${errors[0]}`) }}
                      </small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row align-h="center">
                <b-col cols="12" class="form-btn-dv">
                  <b-button class="form-btn" @click.prevent="submitForm">
                    {{ $t("g.save") }}
                  </b-button>
                </b-col>
              </b-row>
              <!-- **largeInputs -->
            </b-form>
          </validation-observer>
        </b-row>
      </div>

      <EditPasswordModel />

      <overlay-component :isLoading="overlay_loading" />
    </b-row>
  </div>
</template>

<script>
// import store from "@/store";
import { required, email, phone, confirmed, min } from "@validations";

import { ValidationProvider, ValidationObserver } from "vee-validate";

import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormSelect,
  BFormFile,
  BAvatar,
} from "bootstrap-vue";
import loading from "@/components/loading/loading.vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import EditPasswordModel from "@/components/pages/EditProfile/EditPasswordModel/EditPasswordModel.vue";
export default {
  name: "EditProfile",
  data() {
    return {
      form_data: {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        birthdate: null,
        country_id: null,
        phone: null,
        edu_level_id: null,
        experience: null,
        media: [],
      },
      overlay_loading: false,
      password: "DummyPassword",
      countries_options: [],
      levels_options: [],
      overlay: false,
      loading: false,
      path: null,
      coverPath: null,
      profileImgaePlaceholder: require("@/assets/images/placeholders/profileAvatar.png"),
      profileCoverPlaceholder: require("@/assets/images/placeholders/profileCover.png"),
    };
  },
  components: {
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
    loading,
    BAvatar,
    OverlayComponent,
    EditPasswordModel,
  },
  beforeMount() {
    this.getUseData();
    this.getAllcountries();
    this.getAllLevels();
  },
  methods: {
    getUseData() {
      this.loading = true;
      this.$http
        .get("auth/teacher/profile")
        .then((res) => {
          const dataWraping = res.data.data.user;
          // console.log(dataWraping);
          for (let item in this.form_data) {
            // handel form_data
            if (item === "phone") {
              this.form_data[item] = dataWraping[item][0].phone;
            } else if (item == "country_id") {
              this.form_data["country_id"] = dataWraping["country"].id;
            } else if (item == "edu_level_id") {
              this.form_data["edu_level_id"] = dataWraping.teacher.EduLevel.id;
            } else if (item == "experience") {
              this.form_data["experience"] = dataWraping.teacher.experience;
            } else if (item == "media") {
              for (let dataItem of dataWraping.media) {
                dataItem.name === "cover_image"
                  ? (this.coverPath = dataItem.path)
                  : (this.path = dataItem.path);
              }
            } else {
              this.form_data[item] = dataWraping[item];
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.Toast("danger", "error", err.response.data.errors[key][0]);
          }
        });
    },
    getAllcountries() {
      this.loading = true;
      this.$http
        .get("web/countries")
        .then((res) => {
          res.data.data.forEach((ele) => {
            this.countries_options.push({ text: ele.name, value: ele.id });
          });
          this.loading = false;
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.Toast("danger", "error", err.response.data.errors[key][0]);
          }
        });
    },
    getAllLevels() {
      this.loading = true;
      this.$http
        .get("/teacher/edu-levels")
        .then((res) => {
          res.data.data.forEach((ele) => {
            this.levels_options.push({ text: ele.name, value: ele.id });
          });
          this.loading = false;
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.Toast("danger", "error", err.response.data.errors[key][0]);
          }
        });
    },
    profileImageHandler(e) {
      const file = e.target.files[0];
      this.form_data.media = [
        ...this.form_data.media,
        { name: "profileImage", path: file },
      ];
      this.path = URL.createObjectURL(file);
    },
    handleOpenModel() {
      this.$bvModal.show(`edit-password-model`);
    },
    coverImageHandler(e) {
      const file = e.target.files[0];
      this.form_data.media = [
        ...this.form_data.media,
        { name: "coverImage", path: file },
      ];
      this.coverPath = URL.createObjectURL(file);
    },
    submitForm() {
      if (
        this.form_data.middle_name === null ||
        this.form_data.middle_name === ""
      ) {
        delete this.form_data.middle_name;
      }
      this.$refs.form.validate().then((success) => {
        if (success) {
          // append data to FormData object before sending to backend
          this.overlay_loading = true;
          let formData = new FormData();
          for (let item in this.form_data) {
            if (item && item != "media") {
              this.form_data[item] &&
                formData.append(item, this.form_data[item]);
            } else if (this.form_data.media.length) {
              this.form_data.media.map((item) => {
                if (item.name === "profileImage") {
                  formData.append("path", item.path);
                } else if (item.name === "coverImage") {
                  formData.append("cover_image", item.path);
                }
              });
            }
          }
          // call Backend
          this.$http
            .post("auth/teacher/edit-profile?_method=put", formData)
            .then((res) => {
              this.Toast("success", "Sucess", res.data.message);
              this.$store.dispatch("FETCH_DATA_USER");
              setTimeout(() => {
                this.$router.push({ path: "/profile" });
              }, 300);
              this.overlay_loading = false;
            })
            .catch((err) => {
              for (const key in err.response.data.errors) {
                this.Toast("danger", "error", err.response.data.errors[key][0]);
              }
              this.overlay_loading = false;
            });
        } else {
          this.Toast("danger", "Input Not Vaild");
        }
      });
    },
    overlayfun(data) {
      this.overlay = data;
    },
    Toast(variant = null, title, body) {
      this.$bvToast.toast(body, {
        title: title,
        variant,
        solid: true,
      });
    },
  },
};
</script>
<style lang="scss">
@import "./edit-profile.scss";
</style>
